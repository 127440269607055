import "@/plugins/vue-composition-api"
import "@/styles/styles.scss"
import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store/store"
import "@fortawesome/fontawesome-free/css/all.css"
import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import PrimeVue from "primevue/config"
import { getCookie } from "./utils/cookies"
import * as Sentry from "@sentry/vue"

Sentry.init({
  Vue,
  dsn: "https://740b4d41fdcf7f5c50dc6b0efa230221@o4508484897210368.ingest.de.sentry.io/4508484922966096",
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "api-secours.anavel.bzh", "anavel.bzh", "mes-services.anavel.bzh"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(Element)
Vue.use(PrimeVue)

const token = getCookie("jwt")
if (token) {
  store.commit("setAuthState", {
    isAuthenticated: true,
    token,
  })
  if (store.state.userRole !== "invite" && store.state.userRole !== undefined) {
    store.commit("setAuthState", {
      isAuthenticated: true,
      token,
    })
    store.dispatch("fetchUser").then(() => {
      new Vue({
        router,
        store,
        vuetify,
        Element,
        PrimeVue,
        render: h => h(App),
      }).$mount("#app")
    })
  } else {
    store.commit("setAuthStateGuest", {
      isAuthenticated: true,
      token,
    })
    console.log("invite")
    store.dispatch("fetchGuest").then(() => {
      console.log(store.state)
      new Vue({
        router,
        store,
        vuetify,
        Element,
        PrimeVue,
        render: h => h(App),
      }).$mount("#app")
    })
  }
} else {
  new Vue({
    router,
    store,
    vuetify,
    Element,
    PrimeVue,
    render: h => h(App),
  }).$mount("#app")
}
