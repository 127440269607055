import * as dotenv from "dotenv"
dotenv.config()

var config = {
  applicationName: "Mes Services Assurance",
  informations: {
    version: "2.5.2",
  },
  authors: {
    owner: "Anavel",
    developper: [
      {
        name: "FRICHOT ROMAN",
        job: "Developper Manager",
        contact: "roman.frichot@anavel.bzh",
      },
    ],
    gdprContact: "david.audren@anavel.bzh",
    email: "contact@anavel.bzh",
  },
  apiUri: process.env.VUE_APP_API_URI,
  ged: process.env.VUE_APP_GED,
  appUri: process.env.VUE_APP_APP_URI,
  socketUri: process.env.VUE_APP_SOCKET_URI,
  tokenPappers: "90dca6804ce3b81abac691384ae4ae505fe66a709551e245",
}

export default config
