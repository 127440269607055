<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

import "@mdi/font/css/materialdesignicons.css"

Vue.use(Toast, { maxToasts: 5, newestOnTop: true })

import { computed } from "@vue/composition-api"
import { useRouter } from "@/utils"
import LayoutBlank from "@/layouts/Blank.vue"
import LayoutContent from "@/layouts/Content.vue" // CHANGER ICI
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"

import { socket } from "@/utils/socket.js"

export default {
  data() {
    return { isSecure: false }
  },
  components: {
    LayoutBlank,
    LayoutContent,
    CustomeLoader,
  },
  mounted() {
    Notification.requestPermission()
    window.addEventListener("offline", e => {
      console.log("offline")
      this.$router.push("/off").catch(() => {})
    })

    socket.on("notification", data => {
      if (document.visibilityState === "hidden") {
        const n = new Notification(data.info.title, {
          body: data.info.action,
          icon: "https://anavel.bzh/assets/app.png",
          tag: "anavel",
        })
        n.close()
      }

      this.$toast.warning(data.info.title, {
        position: "bottom-right",
        icon: data.info.class,
        timeout: false,
      })
    })

    socket.on("newAlert", () => {
      new Notification("Nouvelle mise à jour", {
        body: "Une information importante à été ajoutée à l'application. Vous pouvez la consulter via le bandeau déroulant en haut de l'écran.",
        icon: "https://anavel.bzh/assets/app.png",
      })
    })

    window.addEventListener("online", e => {
      console.log("online")
    })

    window.addEventListener("onunload", e => {
      this.sendDisconnect()
    })

    document.addEventListener("visibilitychange", event => {
      if (document.visibilityState === "visible") {
        this.sendActivity(true)
      } else {
        this.sendActivity(false)
      }
    })
  },

  beforeMount() {
    //window.addEventListener("beforeunload", this.onUnload)
  },
  beforeDestroy() {
    socket.emit(
      "userDisconnected",
      `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`,
      this.$store.state.user.id,
    )
    // window.addEventListener("beforeunload", this.onUnload)
  },
  methods: {
    sendActivity(status) {
      if (this.$store.state.isAuthenticated) {
        switch (status) {
          case true:
            socket.emit(
              "userActivity",
              `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`,
              "A ouvert l'application",
            )
            break
          case false:
            socket.emit(
              "userActivity",
              `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`,
              "A réduit l'application",
            )
            break
        }
      }
    },
    sendDisconnect() {
      socket.emit("userDisconnected", `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`)
    },
    onLoad(event) {},
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === "blank") return "layout-blank"

      return "layout-content"
    })

    return {
      resolveLayout,
    }
  },
}
</script>
<style lang="scss">
.mullerFont {
  font-family: muller;
}
</style>
